<template>
	<div class="pui-form">
		<!--  -->
		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation v-if="modelLoaded">
			<div class="pui-form-layout">
				<v-layout wrap>
					<v-flex xs12>
						<pui-field-set :title="getTitleAtributos">
							<v-layout wrap>
								<v-flex xs12 md8>
									<pui-text-field
										:label="$t('itinerariotecnico.nombre')"
										v-model="model.nombre"
										toplabel
										maxlength="100"
										disabled
									></pui-text-field>
								</v-flex>
								<v-flex xs12 md2>
									<pui-number-field
										:label="$t('itinerariotecnico.campanya')"
										v-model="model.atribcampanya"
										toplabel
										maxlength="100"
										disabled
									></pui-number-field>
								</v-flex>
							</v-layout>
							<v-layout wrap>
								<v-flex xs12 md10>
									<pui-text-field
										:label="$t('itinerariotecnico.estrategia')"
										v-model="model.descripcion"
										toplabel
										maxlength="100"
										disabled
									></pui-text-field>
								</v-flex>
							</v-layout>
							<v-layout wrap>
								<v-flex xs12 md3>
									<pui-select
										:label="$t('itinerariotecnico.cultivo')"
										toplabel
										disabled
										clearable
										modelName="vlupcultivo"
										v-model="model"
										:itemsToSelect="[{ id: model.cultivo }]"
										:modelFormMapping="{ id: 'cultivo' }"
										itemValue="id"
										itemText="nombre"
									></pui-select>
								</v-flex>
								<v-flex xs12 md4>
									<pui-select
										:label="$t('itinerariotecnico.programagestor')"
										toplabel
										disabled
										clearable
										modelName="vlupprogramagestor"
										v-model="model"
										:itemsToSelect="[{ id: model.programagestor }]"
										:modelFormMapping="{ id: 'programagestor' }"
										itemValue="id"
										itemText="nombre"
									></pui-select>
								</v-flex>
								<v-flex xs12 md3 style="margin-left: 5px">
									<v-layout wrap>
										<span>{{ $t('itinerariotecnico.zonavul') }}</span>
									</v-layout>
									<v-layout wrap style="margin-top: 4px">
										<pui-checkbox
											v-model="model.atribzonavulnerable"
											:true-value="true"
											:disabled="!isCreatingElement"
											:false-value="false"
										></pui-checkbox>
									</v-layout>
								</v-flex>
							</v-layout>
							<v-layout wrap>
								<v-flex xs12 md3>
									<pui-select
										:label="$t('itinerariotecnico.sistemariego')"
										toplabel
										disabled
										clearable
										modelName="vlupsistemariego"
										v-model="model"
										:itemsToSelect="[{ id: model.atribsistemariego }]"
										:modelFormMapping="{ id: 'sistemariego' }"
										itemValue="id"
										itemText="nombre"
									></pui-select>
								</v-flex>
								<v-flex xs12 md4>
									<pui-select
										:label="$t('itinerariotecnico.sistemacertif')"
										toplabel
										disabled
										clearable
										modelName="vlupsistemacertificacion"
										v-model="model"
										:itemsToSelect="[{ id: model.atribsistcertificacion }]"
										:modelFormMapping="{ id: 'atribsistcertificacion' }"
										itemValue="id"
										itemText="nombre"
									></pui-select>
								</v-flex>
								<v-flex xs12 md3>
									<pui-select
										:label="$t('itinerariotecnico.precedente')"
										toplabel
										disabled
										clearable
										modelName="vlupcultivo"
										v-model="model"
										:itemsToSelect="[{ id: model.atribprecedente }]"
										:modelFormMapping="{ id: 'atribprecedente' }"
										itemValue="id"
										itemText="nombre"
									></pui-select>
								</v-flex>
							</v-layout>
							<v-layout wrap>
								<v-flex xs12 md5>
									<pui-select
										:label="$t('itinerariotecnico.zonaverano')"
										toplabel
										disabled
										clearable
										modelName="vlupzonaagroclimatica"
										v-model="model"
										:itemsToSelect="[{ id: model.atribzonaagroclimatica }]"
										:modelFormMapping="{ id: 'atribzonaagroclimatica' }"
										itemValue="id"
										itemText="nombre"
									></pui-select>
								</v-flex>
								<v-flex xs12 md5>
									<pui-select
										:label="$t('itinerariotecnico.zonainvierno')"
										toplabel
										disabled
										clearable
										modelName="vlupzonaagroclimatica"
										v-model="model"
										:itemsToSelect="[{ id: model.atribzonaagroclimaticainv }]"
										:modelFormMapping="{ id: 'atribzonaagroclimaticainv' }"
										itemValue="id"
										itemText="nombre"
									></pui-select>
								</v-flex>
							</v-layout>
						</pui-field-set>
					</v-flex>
				</v-layout>
				<v-layout wrap v-if="model.indicadores">
					<v-flex xs12>
						<pui-field-set :title="getTitleIndicadores">
							<v-layout wrap>
								<v-flex xs12 md2>
									<pui-text-field
										:label="$t('itinerariotecnico.margenbruto')"
										v-model="model.indicadores.margenbrutoha"
										toplabel
										disabled
										maxlength="100"
									></pui-text-field>
								</v-flex>
								<v-flex xs12 md2>
									<pui-text-field
										:label="$t('itinerariotecnico.consumoagua')"
										v-model="model.indicadores.consumoaguaha"
										toplabel
										disabled
										maxlength="100"
									></pui-text-field>
								</v-flex>
								<v-flex xs12 md2>
									<pui-text-field
										:label="$t('itinerariotecnico.produccion')"
										v-model="model.indicadores.rendimiento"
										toplabel
										disabled
										maxlength="100"
									></pui-text-field>
								</v-flex>
								<v-flex xs12 md2>
									<pui-text-field
										:label="$t('itinerariotecnico.consumon')"
										v-model="model.indicadores.consumontotalha"
										toplabel
										disabled
										maxlength="100"
									></pui-text-field>
								</v-flex>
								<v-flex xs12 md2>
									<pui-text-field
										:label="$t('itinerariotecnico.huellacarbono')"
										v-model="model.indicadores.huellacarbonoha"
										toplabel
										disabled
										maxlength="100"
									></pui-text-field>
								</v-flex>
							</v-layout>
						</pui-field-set>
					</v-flex>
				</v-layout>
				<v-layout wrap v-else>
					<v-flex xs12>
						<pui-field-set :title="getTitleIndicadores">
							<v-layout wrap>
								<v-flex xs12 md2>
									<pui-text-field :label="$t('itinerariotecnico.margenbruto')" toplabel disabled maxlength="100"></pui-text-field>
								</v-flex>
								<v-flex xs12 md2>
									<pui-text-field :label="$t('itinerariotecnico.consumoagua')" toplabel disabled maxlength="100"></pui-text-field>
								</v-flex>
								<v-flex xs12 md2>
									<pui-text-field :label="$t('itinerariotecnico.produccion')" toplabel disabled maxlength="100"></pui-text-field>
								</v-flex>
								<v-flex xs12 md2>
									<pui-text-field :label="$t('itinerariotecnico.consumon')" toplabel disabled maxlength="100"></pui-text-field>
								</v-flex>
								<v-flex xs12 md2>
									<pui-text-field :label="$t('itinerariotecnico.huellacarbono')" toplabel disabled maxlength="100"></pui-text-field>
								</v-flex>
							</v-layout>
						</pui-field-set>
					</v-flex>
				</v-layout>
			</div>
			<!-- footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns :formDisabled="formDisabled" :saveDisabled="saving" :save="save" :back="back"></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';

export default {
	name: 'itinerariotecnico-form',
	mixins: [PuiFormMethodsMixin],

	data() {
		return {
			modelName: 'itinerariotecnico',
			model: 'itinerariotecnico',
			saving: false
		};
	},
	methods: {},
	computed: {
		getTitleAtributos() {
			return this.$t('itinerariotecnico.atributos');
		},
		getTitleIndicadores() {
			return this.$t('itinerariotecnico.indicadores');
		}
	}
};
</script>

<style lang="css" scoped>
</style>
